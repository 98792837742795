import React, { Component } from 'react';
import TemplateList from '../components/TemplateList';
import Modal from 'react-modal';
import Page from '../components/Page';
import { Heading, Close, Subhead } from 'rebass';
import CommunityBanner from '../components/CommunityBanner';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Text from '../components/Text';
import TextLink from '../components/TextLink';

const ModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(218, 218, 220, .75)',
    overflowY: 'auto',
  },
  content: {
    position: 'absolute',
    fontFamily: 'sans-serif',
    top: '80px',
    left: '10%',
    right: '10%',
    bottom: 'auto',
    border: '0px',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    maxWidth: '1024px',
    marginRight: 'auto',
    marginLeft: 'auto',
    scrollBehavior: 'smooth',
  },
};

const BackButton = styled(Close)`
  position: absolute;
  right: 31px;
  width: 38px;
  height: 50px;
  font-size: 30px;
  color: gray;

  :hover {
    box-shadow: none;
    color: #dee1e5;
    cursor: pointer;
  }
`;

class templates extends Component {
  back = e => {
    e.stopPropagation();
    window.history.back();
  };
  render() {
    return (
      <div>
        <Page />
        <CommunityBanner />
        <Modal
          isOpen={Boolean('true')}
          onRequestClose={this.back}
          style={ModalStyle}
        >
          <BackButton onClick={this.back} />
          <Heading textAlign="center" p="20px">
            🚀 Launch Starters{' '}
          </Heading>
          <Text textAlign="center" p="20px" mx="15%">
            Everything I build is based on one of these launch starters. They're
            all designed to be more functional than boilerplates, and more
            easily customized than templates. The result? Faster launches,
            better products. You can get access to them by joining the{' '}
            <TextLink to="/community">community.</TextLink>
          </Text>
          <TemplateList
            templates={this.props.data.allContentfulTemplates.edges}
          />
        </Modal>
      </div>
    );
  }
}

export default templates;

export const query = graphql`
  query TemPages {
    contentfulPages(title: { eq: "Community" }) {
      id
      header
      bodyTitle
      hearts
      body {
        body
        childMarkdownRemark {
          id
          html
        }
      }
    }
    allContentfulTemplates {
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          featuredImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          screenshots {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
